import lottie from "lottie-web"
import { FC, useEffect, useRef } from "react"

type LottiePlayerProps = {
  autoplay?: boolean
  className?: string
  loop?: boolean
  loopDelay?: number
  name: string
}

const LottiePlayer: FC<LottiePlayerProps> = ({
  autoplay = true,
  className,
  loop = false,
  loopDelay,
  name,
}) => {
  const animationContainer = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!!animationContainer.current) {
      const animation = lottie.loadAnimation({
        autoplay,
        container: animationContainer.current,
        loop: loop && !loopDelay,
        name,
        path: `/lotties/${name}.json`,
      })
      if (loop && loopDelay) {
        animation.addEventListener("complete", () => {
          setTimeout(() => {
            animation.goToAndPlay(0)
          }, loopDelay)
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return <div ref={animationContainer} className={className}></div>
}

export default LottiePlayer
